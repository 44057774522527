// Hide menu button on mobile as no navigation

.menuMainAlt {
  display: none!important;
}

// Simplified Artez footer

.pageFooter { 
  display: block;
  padding: 3em 0;
  ul.socialIcons{
    margin-bottom:20px;
    display: none;
  }
  .Footercredits{
    text-align: center;
  }
  ul.footerLinks li{
    display: inline-block;
    margin: 0 20px 20px 0;
  }
}

// Donation forms - hide 'Display your name with your donation on our site'

.formQuestion.displayName.labelCheckBoxPair {
  display: none;
}

// Donation form - hide SMS and post contact options

.formQuestion.formSecondStep.contactViaSMS.labelCheckBoxPair,
.formQuestion.formSecondStep.contactViaPost.labelCheckBoxPair {
  display: none;
}

// Donation thank you page - hide return to home page button

.donationThankYouWrapper .donationShare a.cta-button {
  display: none;
}